define("discourse/plugins/discourse-karma-system/discourse/lib/plugin-debug", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pluginDebug;
  //console.log('loading debug');

  function pluginDebug(msg) {
    let siteSettings = (0, _helpers.helperContext)().siteSettings;
    if (siteSettings.karma_system_enable_debug_mode) {
      console.log(msg);
    }
  }
});