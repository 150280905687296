define("discourse/plugins/discourse-karma-system/discourse/initializers/karma-system-init", ["exports", "discourse/lib/plugin-api", "discourse/lib/transform-post", "virtual-dom", "discourse-common/lib/get-url", "discourse/plugins/discourse-karma-system/discourse/lib/karma-block", "discourse/plugins/discourse-karma-system/discourse/lib/plugin-debug"], function (_exports, _pluginApi, _transformPost, _virtualDom, _getUrl, _karmaBlock, _pluginDebug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
  Karma system
  
  Decorates post with karma
  
  */
  var _default = _exports.default = {
    name: 'karma-system-init',
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      (0, _pluginApi.withPluginApi)('0.1', api => {
        //console.log("settings accessed from initializer: " + siteSettings.header_dropdown_category_count);

        api.includePostAttributes('user_likes_received', 'user_last_seen_at'); // (automatically added to transform-post.js.es6)

        api.decorateWidget('poster-name:after', function (helper) {
          (0, _pluginDebug.default)('test - decoratewidget');
          const attrs = helper.attrs;
          const user_custom_fields = attrs.user_title;
          const karmic_points = attrs.user_likes_received;
          const karma_enabled = siteSettings.karma_system_enabled;
          if (!karma_enabled) return;
          const karma_difficulty = siteSettings.karma_system_difficulty;
          return (0, _karmaBlock.default)(karmic_points, karma_difficulty, siteSettings.karma_system_enable_debug_mode);
        });
      });
    }
  };
});