define("discourse/plugins/discourse-karma-system/discourse/lib/karma-block", ["exports", "virtual-dom"], function (_exports, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getKarma;
  /* 
  
  karma-block.js module
  
  Provide helper functions that generates karma block and returns vdom object 
  
  */
  function getKarma(points, dif, debug_mode) {
    //var points = Math.floor(Math.random() * (80000 - 100 + 1)) + 100;
    //var dif = 2;

    const karma_pip_src = '/plugins/discourse-karma-system/images/karmapos.gif';
    const karma_pip_high_src = '/plugins/discourse-karma-system/images/karmahighpos.gif';
    const karma_bar_start_src = '/plugins/discourse-karma-system/images/karmabarstart.gif';
    const karma_bar_mid_src = '/plugins/discourse-karma-system/images/karmabarmid.gif';
    const karma_bar_end_src = '/plugins/discourse-karma-system/images/karmabarend.gif';
    const pips = Math.ceil(Math.sqrt(Math.sqrt(points / dif)));
    if (debug_mode) console.log("Karma Block: (Likes: " + points + " @ difficulty: " + dif + " -> Pips: " + pips + ")");
    let description;
    if (debug_mode) description = "(Likes: " + points + " @ difficulty: " + dif + " -> Pips: " + pips + ")";
    let vdom_object = [];
    if (pips <= 13) {
      for (let i = 1; i <= pips; i++) {
        let src;
        if (i <= 5) {
          src = karma_pip_src;
        } else {
          src = karma_pip_high_src;
          i = i + 1;
        }
        vdom_object.push((0, _virtualDom.h)('img', {
          attributes: {
            src: src,
            title: description
          }
        }));
      }
    } else {
      // display karma bar
      vdom_object.push((0, _virtualDom.h)('img', {
        attributes: {
          src: karma_bar_start_src
        }
      }));
      vdom_object.push((0, _virtualDom.h)('img', {
        attributes: {
          src: karma_bar_mid_src,
          height: '16px',
          width: `${pips}px`,
          title: description
        }
      }));
      vdom_object.push((0, _virtualDom.h)('img', {
        attributes: {
          src: karma_bar_end_src
        }
      }));
    }
    const contents = [(0, _virtualDom.h)('span', {
      className: 'poster-karma'
    }, vdom_object)];
    return contents;
  }
});