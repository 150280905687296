define("discourse/plugins/discourse-karma-system/discourse/widgets/user-karma", ["discourse/widgets/widget", "virtual-dom", "discourse/plugins/discourse-karma-system/discourse/initializers/karma-system-init", "discourse-common/lib/get-url", "discourse/plugins/discourse-karma-system/discourse/lib/karma-block", "discourse/plugins/discourse-karma-system/discourse/lib/plugin-debug"], function (_widget, _virtualDom, _karmaSystemInit, _getUrl, _karmaBlock, _pluginDebug) {
  "use strict";

  /*
  
  Karma Widget for user-card and user profile (mounted via /connectors)
  
  */
  (0, _widget.createWidget)('user-karma', {
    tagName: 'span.poster-karma-rating',
    html(attrs) {
      const karmic_points = attrs.likes_received;

      // attrs = model (userserializer v pripade user, usercardserializer v pripade user-card)
      //console.dir(attrs);
      //const contents = getKarmaLight(karmic_lights, true);

      //console.log("settings accessed from widget: " + this.siteSettings.header_dropdown_category_count);

      (0, _pluginDebug.default)('test-widget');

      // calculate karma pips and generate img code
      const siteSettings = this.siteSettings;
      const karma_enabled = siteSettings.karma_system_enabled;
      if (!karma_enabled) return;
      const karma_difficulty = siteSettings.karma_system_difficulty;
      return (0, _karmaBlock.default)(karmic_points, karma_difficulty, siteSettings.karma_system_enable_debug_mode);
    }
  });
});